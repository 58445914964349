<template>
  <v-container fluid class="px-10">
    <v-row>
      <v-col cols="auto" xs="12" sm="6"> </v-col>
      <v-col v-if="hasAuthorityConfigEditCopyMovementRules" cols="auto" xs="12" sm="6">
        <v-btn
          @click="createNewMovementRule"
          x-large
          class="float-right"
          color="primary"
          min-height="52"
        >
          <v-icon style="font-size: 17px" class="mr-2">fa-cog</v-icon>
          Configuração de regras
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="auto" xs="12" sm="6">
        <h1 class="secondary--text">Regras de Movimentação</h1>
      </v-col>
      <v-col cols="auto" xs="12" sm="6">
        <div class="float-right">
          <v-menu
            v-model="showFilters"
            :close-on-content-click="false"
            :max-width="500"
            offset-overflow
          >
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="primary"
                >fa-filter</v-icon
              >
            </template>

            <v-card>
              <div class="pt-2">
                <v-row>
                  <v-col cols="12">
                    <v-icon class="float-right pr-3" @click="showFilters = false">
                      fa-times
                    </v-icon>
                  </v-col>
                </v-row>
                <v-row style="max-height: 80px">
                  <v-col cols="5">
                    <label class="ml-5">Operadora</label>
                  </v-col>
                  <v-col cols="7">
                    <v-autocomplete
                      class="mr-10"
                      placeholder="Selecione"
                      outlined
                      :items="insuranceCarriers"
                      v-model="insuranceCarrierId"
                      item-value="id"
                      item-text="xipp_commercial_name"
                      :loading="loadingInsuranceCarrier"
                      :disabled="($route.query.insuranceCarrierId || $route.query.financialGroupId) ? true : financialGroupId ? true : false"
                    />
                  </v-col>
                </v-row>
                <v-row style="max-height: 80px">
                  <v-col cols="5">
                    <label class="ml-5">Grupo Financeiro</label>
                  </v-col>
                  <v-col cols="7">
                    <v-autocomplete
                      class="mr-10"
                      placeholder="Selecione"
                      outlined
                      :items="financialGroups"
                      item-value="id"
                      item-text="name"
                      v-model="financialGroupId"
                      @input="loadContractsByFinancialGroupId()"
                      :loading="loadingFinancialGroups"
                      :disabled="($route.query.financialGroupId || $route.query.insuranceCarrierId) || this.insuranceCarrierId ? true : false"
                    />
                  </v-col>
                </v-row>
                <v-row style="max-height: 80px">
                  <v-col cols="5">
                    <label class="ml-5">Contrato</label>
                  </v-col>
                  <v-col cols="7">
                    <v-autocomplete
                      class="mr-10"
                      placeholder="Selecione"
                      outlined
                      :items="contracts"
                      item-value="id"
                      item-text="text"
                      v-model="contractId"
                      @input="loadSubContractsByContractId()"
                      :loading="loadingContracts"
                      :disabled="insuranceCarrierId ? true : false"
                    />
                  </v-col>
                </v-row>

                <v-row style="max-height: 80px">
                  <v-col cols="5">
                    <label class="ml-5">Sub-Contrato</label>
                  </v-col>
                  <v-col cols="7">
                    <v-autocomplete
                      :items="subContracts"
                      :loading="loadingSubContracts"
                      v-model="subContractId"
                      class="mr-10"
                      placeholder="Selecione"
                      item-value="id"
                      item-text="text"
                      outlined
                      :disabled="insuranceCarrierId ? true : false"
                    />
                  </v-col>
                </v-row>

                <v-row style="max-height: 80px">
                  <v-col cols="5">
                    <label class="ml-5">Status</label>
                  </v-col>
                  <v-col cols="7">
                    <v-autocomplete
                      class="mr-10"
                      :items="statusList"
                      v-model="status"
                      placeholder="Selecione"
                      outlined
                    />
                  </v-col>
                </v-row>
              </div>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="" @click="removeFilter()">Limpar</v-btn>
                <v-btn color="primary" @click="applyFilter()">Filtrar</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="movementRules"
          :loading="loadingTable"
          :loading-text="message"
          :items-per-page="10"
          hide-default-footer
          item-key="specificRuleId"
          class="elevation-1"
        >
          <template v-slot:[`item.insuranceCarrier`]="{ item }">
            {{ getInsuranceCarrierText(item) }}
          </template>
          <template v-slot:[`item.financialGroup`]="{ item }">
            {{ getFinancialGroupText(item) }}
          </template>
          <template v-slot:[`item.contract`]="{ item }">
            {{ item ? getContractText(item) : '-' }}
          </template>
          <template v-slot:[`item.subcontract`]="{ item }">
            {{ item ? getSubContractText(item) : '-' }}
          </template>
          <template v-slot:[`item.rules`]="{ item }">
            {{ getRulesText(item) }}
          </template>
          <template v-slot:[`item.defaultValue`]="{ item }">
            {{ item.defaultValue ? item.defaultValue : '-'}}
          </template>
          <template v-slot:[`item.configValue`]="{ item }">
            {{ item.configValue ? item.configValue : '-'}}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            {{ item.status ? "ATIVO" : "INATIVO" }}
          </template>
          <template v-slot:[`header.actions`]="{ header }">
            <span>{{ hasAuthorityConfigEditCopyMovementRules ? header.text : null }}</span>
          </template>
          <template v-if="hasAuthorityConfigEditCopyMovementRules" v-slot:[`item.actions`]="{ item }">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <div class="menu">
                <v-list dense>
                  <v-list-item-group color="primary">
                    <v-list-item
                      v-if="hasAuthorityConfigEditCopyMovementRules"
                      @click="editMovementRule(item)"
                    >
                      <v-list-item-icon>
                        <v-icon >mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="hasAuthorityConfigEditCopyMovementRules"
                      @click="duplicateMovementRule(item)"
                    >
                      <v-list-item-icon>
                        <v-icon >mdi-content-copy</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Copiar configuração</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-menu>
          </template>
        </v-data-table>
        <div class="float-right pt-2">
          <v-pagination
            total-visible="9"
            v-model="page"
            :length="totalPages"
            :disabled="loadingTable"
            @input="selectPagination()"
          />
        </div>
      </v-col>

      <v-col cols="12" align="end">
        <v-footer>
          <v-row class="d-flex pt-6 justify-end">
            <v-btn
              x-large
              outlined
              color="textPrimary"
              width="250px"
              @click="onClickCancel()"
            >
              Voltar
            </v-btn>
          </v-row>
        </v-footer>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="deleteRuleDialog" transition="dialog-bottom-transition" max-width="700px">
        <v-card class="pa-5">
          <v-card-title class="text-h5 justify-center primary--text">Você tem certeza que deseja excluir essa regra?</v-card-title>
          <v-card-actions>
          <v-row class="mt-6">
            <v-spacer></v-spacer>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3">
              <v-btn
                color="#3B495B"
                outlined
                block
                @click="closeDialogConfirmDelete()"
              >
                Cancelar
              </v-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3">
              <v-btn
                color="#3B495B"
                block
                style="color: white"
                @click="dialogConfirmDelete()"
              >
                Confirmar
              </v-btn>
            </v-col>
          </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="snackbar.show"
        timeout="10000"
        top
        elevation="24"
        :color="snackbar.type"
      >
        <div class="text-center">{{ snackbar.text }}</div>
        <template v-slot:action="{ attrs }">
          <v-icon
            text
            dark
            v-bind="attrs"
            @click="snackbar.show = false"
          >
            close
          </v-icon>
        </template>
      </v-snackbar>
    </v-row>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="120">
        <div>Carregando...</div>
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import UserUtils from '@/shared/utils/user-utils';
import FinancialGroupService from '@/services-http/contract/FinancialGroupService';
import ContractService from '@/services-http/contract/ContractService';
import MovementRuleSpecific from '@/services-http/sdi/MovementRulesSpecifiedService';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';

export default {
  data: () => ({
    deleteRuleDialog: false,
    hasAuthorityConfigEditCopyMovementRules: false,
    loadingTable: false,
    showFilters: false,
    overlay: false,
    loadingInsuranceCarrier: false,
    loadingContracts: false,
    loadingFinancialGroups: false,
    loadingSubContracts: false,
    insuranceCarriers: [],
    financialGroups: [],
    contracts: [],
    subContracts: [],
    movementRules: [],
    insuranceCarrierId: null,
    financialGroupId: null,
    contractId: null,
    subContractId: null,
    movementRuleId: null,
    status: null,
    snackbar: {
      show: false,
      type: '',
      text: '',
    },
    page: 1,
    totalPages: 0,
    message: 'Carregando...',
    categorias: ['ANS', 'Bradesco', 'Sul América'],
    statusList: ['Todos', 'Ativo', 'Inativo'],
    headers: [
      {
        text: 'Operadora',
        align: 'start',
        value: 'insuranceCarrier',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Grupo Financeiro',
        align: 'start',
        value: 'financialGroup',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Contrato',
        value: 'contract',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Sub-Contrato',
        value: 'subcontract',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Regra',
        value: 'name',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Valor Padrão',
        value: 'defaultValue',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Valor Configurado',
        value: 'configValue',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Status',
        value: 'status',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Ações',
        value: 'actions',
        align: 'right',
        class: 'text--truncate',
        sortable: false,
      },
    ],
    type: {
      movementRule: { id: null },
      insuranceCarrier: { id: null },
      financialGroup: { id: null },
      isEdit: false,
      isDuplicate: false,
    },
  }),

  mixins: [
    FinancialGroupsMixin,
    InsuranceCarriersMixin,
  ],

  async mounted() {
    this.loadingTable = true;
    this.getInsuranceCarriers();
    this.getFinancialGroups();
    await this.loadAuthorityMovementRules();
    await this.loadRulesByQuery();
    await this.verifyExistQuery();
    if (this.financialGroupId) {
      await this.loadContractsByFinancialGroupId();
    }
  },

  methods: {
    async loadAllContracts() {
      this.movementRules = [];
      const query = {
        page: this.page - 1,
        size: 10,
      };
      this.MovementRuleSpecific.FindAllByFilter(query).then((response) => {
        if (response && response.data.content) {
          this.movementRules = response.data.content;
          this.totalPages = response.data.totalPages;
          this.loadingTable = false;
        }
      }).catch(() => {
        this.loadingTable = false;
      });
    },
    async loadAuthorityMovementRules() {
      this.hasAuthorityConfigEditCopyMovementRules = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_regras_movimentacao_configuracao_editar_copiar' });
    },
    async loadContractsByFinancialGroupId() {
      if (this.financialGroupId && this.financialGroupId !== 'null' && this.financialGroupId !== '') {
        this.loadingContracts = true;
        this.contracts = [];
        this.insuranceCarrierId = null;
        this.contractId = null;
        const query = `financialGroupIds=${this.financialGroupId}`;
        await this.contractService.FindAllByFilters(query).then((response) => {
          if (response && response.data && response.data.length > 0) {
            const subContractsFalse = response.data.filter((contracts) => contracts.subcontract === false);
            this.contracts = subContractsFalse;
            if (this.contracts.length) {
              this.contracts = this.contracts.map((item) => ({
                ...item,
                text: (`${(item.benefit_id && item.benefit_id.name ? item.benefit_id.name : item.benefit_id ? item.benefit_id : '-')} - ${(item.carrier_id && item.carrier_id.xipp_commercial_name ? item.carrier_id.xipp_commercial_name : '-')} - ${item.policy ? item.policy : '-'} - ${item.contract_owner_id && item.contract_owner_id.legal_name ? item.contract_owner_id.legal_name : '-'}`),
              }));
            }
            this.loadingContracts = false;
          }
        }).catch(() => {
          this.customizeSnackbarMessage('error', 'Ocorreu um erro ao carregar os contratos');
          this.loadingContracts = false;
        });
      }
    },
    async loadSubContractsByContractId() {
      if (this.contractId && this.contractId !== '' && this.contractId !== 'null') {
        this.loadingSubContracts = true;
        this.subcontracts = [];
        this.subContractId = null;
        await this.contractService.FindByParentId(this.contractId).then((response) => {
          if (response && response.data && response.data.length > 0) {
            const subContractsTrue = response.data.filter((contracts) => contracts.subcontract === true);
            this.subcontracts = subContractsTrue;
            if (this.subcontracts.length) {
              this.subContracts = this.subcontracts.map((item) => ({
                ...item,
                text: (`${item.subcontract_number ? item.subcontract_number : '-'} - ${item.contract_owner_id && item.contract_owner_id.legal_name ? item.contract_owner_id.legal_name : '-'}`),
              }));
              this.subContracts.sort((a, b) => a.subcontract_number.localeCompare(b.subcontract_number, { numeric: true }));
            }
            this.loadingSubContracts = false;
          }
        }).catch(() => {
          this.loadingSubContracts = false;
          this.customizeSnackbarMessage('error', 'Ocorreu um erro ao carregar os sub-contratos');
        });
      }
    },
    async loadRulesByQuery() {
      const query = {
        page: this.page - 1,
        size: 10,
      };
      if (this.$route.query.insuranceCarrierId) {
        query.insuranceCarrierId = this.$route.query.insuranceCarrierId;
        // eslint-disable-next-line radix
        this.insuranceCarrierId = parseInt(this.$route.query.insuranceCarrierId);
        this.findByInsuranceCarrier(query);
      } else if (this.$route.query.financialGroupId) {
        query.financialGroupId = this.$route.query.financialGroupId;
        // eslint-disable-next-line radix
        this.financialGroupId = parseInt(this.$route.query.financialGroupId);
        this.findByFinancialGroup(query);
      } else {
        this.loadAllContracts();
      }
    },
    async findByInsuranceCarrier(query) {
      this.MovementRuleSpecific.FindAllByFilter(query).then((response) => {
        if (response && response.data.content) {
          this.movementRules = response.data.content;
          this.totalPages = response.data.totalPages;
          this.loadingTable = false;
        }
      }).catch(() => {
        this.loadingTable = false;
      });
    },
    async findByFinancialGroup(query) {
      this.MovementRuleSpecific.FindAllByFilter(query).then((response) => {
        if (response && response.data.content) {
          this.movementRules = response.data.content;
          this.totalPages = response.data.totalPages;
          this.loadingTable = false;
        }
      }).catch(() => {
        this.loadingTable = false;
      });
    },
    async verifyExistQuery() {
      if (this.$route.query) {
        if (this.$route.query.insuranceCarrierId) {
          // eslint-disable-next-line radix
          this.type.insuranceCarrier.id = parseInt(this.$route.query.insuranceCarrierId);
        } else if (this.$route.query.financialGroupId) {
          // eslint-disable-next-line radix
          this.type.financialGroup.id = parseInt(this.$route.query.financialGroupId);
        }
        sessionStorage.setItem('typeRule', JSON.stringify(this.type));
      }
    },
    removeFilter() {
      if (this.$route.query.financialGroupId) {
        this.insuranceCarrierId = null;
        this.contractId = null;
        this.subContractId = null;
        this.status = null;
      } else if (this.$route.query.insuranceCarrierId) {
        this.financialGroupId = null;
        this.status = null;
      } else {
        this.insuranceCarrierId = null;
        this.financialGroupId = null;
        this.contractId = null;
        this.subContractId = null;
        this.status = null;
      }
    },
    applyFilter() {
      this.loadingTable = true;
      const status = this.verifyStatus();

      const query = {
        insuranceCarrierId: this.insuranceCarrierId,
        financialGroupId: this.financialGroupId,
        contractId: this.contractId,
        subContractId: this.subContractId,
        page: this.page = 0,
        size: 10,
        status,
      };
      this.page = 1;
      this.filter(query);
      this.showFilters = false;
    },
    verifyStatus() {
      let { status } = this;
      if (status === 'Ativo') {
        status = true;
      } else if (status === 'Inativo') {
        status = false;
      } else {
        status = null;
      }
      return status;
    },
    filter(query) {
      const filteredParams = Object.entries(query).filter(([key, value]) => value !== null && value !== 'null' && value !== '').reduce((element, [key, value]) => ({ ...element, [key]: value }), {});
      this.loadingTable = true;
      this.movementRules = [];
      this.MovementRuleSpecific.FindAllByFilter(filteredParams).then((response) => {
        if (response && response.data.content) {
          this.movementRules = response.data.content;
          this.totalPages = response.data.totalPages;
        }
        this.loadingTable = false;
      }).catch(() => {
        this.loadingTable = false;
      });
    },
    selectPagination() {
      this.loadingTable = true;
      const query = {};
      if (this.insuranceCarrierId && this.insuranceCarrierId !== 'null' && this.insuranceCarrierId !== '') {
        query.insuranceCarrierId = this.insuranceCarrierId;
      }

      if (this.financialGroupId && this.financialGroupId !== 'null' && this.financialGroupId !== '') {
        query.financialGroupId = this.financialGroupId;
      }

      if (this.contractId) {
        query.contractId = this.contractId;
      }

      if (this.subContractId && this.subContractId !== 'null' && this.subContractId !== '') {
        query.subContractId = this.subContractId;
      }

      if (this.verifyStatus()) {
        query.status = this.verifyStatus();
      }

      query.page = this.page - 1;
      query.size = 10;

      this.MovementRuleSpecific.FindAllByFilter(query).then((response) => {
        if (response && response.data.content) {
          this.movementRules = response.data.content;
          this.totalPages = response.data.totalPages;
          this.loadingTable = false;
        }
        this.loadingTable = false;
      }).catch(() => {
        this.loadingTable = false;
      });
    },
    getInsuranceCarrierText(item) {
      let carrier = null;

      if (item && item.insuranceCarrier && item.insuranceCarrier.id) {
        carrier = this.insuranceCarriers.find((carrier) => carrier.id === Number(item.insuranceCarrier.id));
      }

      if(carrier && carrier.xipp_commercial_name){
        return carrier.xipp_commercial_name
      }

      return '-';
    },
    getFinancialGroupText(item) {
      // eslint-disable-next-line no-nested-ternary
      return item && item.financialGroup && item.financialGroup.name ? item.financialGroup.name : (item.financialGroup && item.financialGroup.id ? item.financialGroup.id : '-');
    },
    getContractText(item) {
      let text = '';
      if (item && item.contract) {
        text = `${item.contract.benefit ? item.contract.benefit : '-'} - ${item.contract.carrierName ? item.contract.carrierName : '-'} - ${item.contract.legalName ? item.contract.legalName : '-'}`;
      } else {
        text = '-';
      }
      return text;
    },
    getSubContractText(item) {
      let text = '';
      if (item && item.subContract) {
        text = `${item.subContract.subContractNumber ? item.subContract.subContractNumber : '-'} - ${item.subContract.legalName ? item.subContract.legalName : '-'}`;
      } else {
        text = '-';
      }
      return text;
    },
    getRulesText(item) {
      let text = '';
      if (item && item.rules) {
        item.rules.forEach((element) => {
          if (item.rules.length === 1) {
            text += `${element.name}`;
          } else {
            text += `${element.name}, `;
          }
        });
      }
      return text;
    },
    createNewMovementRule() {
      this.$router.push({ name: 'MovementRuleNew', query: this.$route.query });
    },
    duplicateMovementRule(item) {
      this.type.movementRule.id = item.id;
      if (item.insuranceCarrier && item.insuranceCarrier.id) {
        this.type.insuranceCarrier.id = item.insuranceCarrier.id;
        this.type.financialGroup.id = null;
      } else if (item.financialGroup && item.financialGroup.id) {
        this.type.financialGroup.id = item.financialGroup.id;
        this.type.insuranceCarrier.id = null;
      }
      this.type.isEdit = false;
      this.type.isDuplicate = true;
      sessionStorage.setItem('typeRule', JSON.stringify(this.type));
      this.$router.push({ name: 'MovementRuleNew', query: this.$route.query });
    },
    editMovementRule(item) {
      this.type.movementRule.id = item.id;
      if (item.insuranceCarrier && item.insuranceCarrier.id) {
        this.type.insuranceCarrier.id = item.insuranceCarrier.id;
        this.type.financialGroup.id = null;
      } else if (item.financialGroup && item.financialGroup.id) {
        this.type.financialGroup.id = item.financialGroup.id;
        this.type.insuranceCarrier.id = null;
      }
      this.type.isEdit = true;
      this.type.isDuplicate = false;
      sessionStorage.setItem('typeRule', JSON.stringify(this.type));
      this.$router.push({ name: 'MovementRuleEdit', query: this.$route.query, params: { id: item.id } });
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },

    onClickCancel() {
      this.$router.push({ name: 'HomeAllMenus' });
    },
  },

  created() {
    this.userUtils = new UserUtils();
    this.financialGroupService = new FinancialGroupService();
    this.contractService = new ContractService();
    this.MovementRuleSpecific = new MovementRuleSpecific();
  },
};
</script>

<style lang="scss" scoped></style>;
